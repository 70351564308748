<!-- 顶部导航 -->
<template>
    <div class="my-tab">
        <div class="tab-item"  @click="tab(0)" :class="index==0?'active-tab':''">{{$t("userPage.text53")}}（{{ msgUnReadTotalVuex || 0 }}）</div>
        <div class="tab-item"  @click="tab(1)" :class="index==1?'active-tab':''">{{$t("userPage.text54")}}（{{ noticeUnReadTotalVuex || 0 }}）</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'noticeUnReadTotalVuex', // 未读公告条数
            'msgUnReadTotalVuex', // 未读通知条数
        ])
    },
    props: {
        tabIndex: { // 外部注入的tab下标
            type: Number,
            default: 0,
        },
    },
    data () {
        return {
            index: 0
        }
    },
    created () {
        this.index = this.tabIndex
    },
    watch: {
        tabIndex (val) {
            this.index = val
        },
        '$route.query.t': {
            handler () {
                setTimeout(() => {
                    const i = this.$route.query.tab
                    if (!isNaN(i))  this.tab(this.$route.query.tab)
                })
            },
            immediate: true
        }
    },
    methods: {
        tab (index) {
            this.index = index
            this.$emit('tab', index)
        }
    }
}
</script>

<style scoped lang="scss">
.my-tab {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tab-item {
        min-width: 160px;
        padding: 0 5px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        position: relative;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bold;
        border: 1px solid #ce06cd;
        border-left: none;
        border-radius: 7px 7px 0 0;
        color: #ce06cd;
        background: none;
    }
    .tab-item:nth-child(1){
        border-left: 1px solid #ce06cd;
    }
    .active-tab {
        background: #ce06cd;
        color: #fff;
    }
}
</style>