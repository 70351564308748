<!-- 公告列表 -->
<template>
    <div class="page-notice">
        <!-- 公告列表 -->
        <div v-if="!showDetail" class="notice-bg">
            <div  class="notice-item" v-for="(item, i) in systemNoticeList" :key="i" @click="readItem(item, i)">
                <div class="img-box">
                    <img loading="lazy" v-lazy="item.readFlag == 0 ? require('../../../assets/images/my/news-i.png') : require('../../../assets/images/my/news.png')"  alt="">
                </div>
                <div class="content-box">
                    <div class="title-box">
                        <div class="title" :class="{'title-i':item.readFlag == 0}">{{ item.subject }}</div>
                        <div class="time">{{ item._publishedAt }}</div>
                    </div>
                    <div class="content">
                        {{ item.content }}
                    </div>
                </div>
            </div>
            <div v-if="!systemNoticeList.length" class="null-box">
                <img loading="lazy" v-lazy="require('../../../assets/images/my/null.png')">
                <div>{{$t("userPage.text162")}}</div>
            </div>
            <!-- 分页 -->
            <div class="pagination-boxs" style="padding-right: 30px;">
                <Pagination
                layout="prev, pager, next"
                :hide-on-single-page="true"
                :current-page.sync="form.currPage"
                :page-size="form.pageSize"
                :total="systemNoticeTotal"
                @size-change="getData"
                @current-change="getData"
                ></Pagination>
            </div>
        </div>
        <div v-else class="notice-detail notice-bg">
            <div class="title-box">
                <div class="title">{{ info.subject }}</div>
                <div class="time">{{ info._publishedAt }}</div>
            </div>
            <div class="content-box">
                {{ info.content }}
            </div>
        </div>
        <div v-if="showDetail" class="back" @click="back">{{$t("userPage.text45")}}</div>
    </div>
</template>

<script>
import { _noticeList, _readNotice } from '../../../core/api/modules/system'
import { mapGetters } from 'vuex'
import { Pagination } from 'element-ui'

export default {
    components: {
        Pagination
    },
    computed: {
        ...mapGetters([
            'systemNoticeList', // 公告列表
            'systemNoticeTotal', // 总条数
            'systemNoticeUnread', // 是否有未读公告
        ])
    },
    data () {
        return {
            showDetail:false,
            info:null,
            form: {
                currPage: 1,
                pageSize: 10,
            }
        }
    },
    created () {
        _noticeList(this.form)
    },
    methods: {
        // 获取数据
        getData () {
            setTimeout(() => {
                _noticeList(this.form)
            }, 0)
        },
        // 打开或关闭详情
        readItem (item, i) {
            this.showDetail = true
            this.info = item
            if (item.readFlag == 0) { // 打开未读消息
                _readNotice([item.id], i)
            }
            document.body.scrollTop = 0;
        },
        back() {
            this.showDetail = false
        }
    }
}
</script>

<style scoped lang="scss">
.notice-item {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-left: 10px;
    border-bottom: 1px solid #DEDEDE;
    .content-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 80px;
        flex: 1;
        margin-left: 40px;
        .title-box {
            display: flex;
            justify-content: space-between;
            .title {
                font-weight:700;
                color:#000;
                font-size:18px;
            }
            .time {
                color:#6f6f6f;
                font-size:16px;
            }
            .title-i {
                color:#00A6FF;
            }
        }
        .content {
            width:620px;
            height:20px;
            margin-top: 6px;
            color:#6f6f6f;
            font-size:14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
.null-box {
    height: 290px;
    margin-top: 50px;
    font-weight: 700;
    text-align: center;
    // border-bottom: 2px solid #000;
    img {
        width: 160px;
        height: 210px;
        margin-left: -8px;
        margin-bottom: 12px;
    }
}

.notice-bg{
    min-height: 400px;
    padding: 20px 40px 10px;
    border-radius: 10px;
    background: #E8F8FF;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
}
.notice-detail {
    min-height: 400px;
    padding: 20px 40px 10px;
    border-radius: 10px;
    background: #E8F8FF;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 30%);
    .title-box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        border-bottom: 2px solid #DEDEDE;
        .title {
            font-weight:700;
            color:#00A6FF;
            font-size:18px;
        }
        .time {
            color:#6f6f6f;
            font-size:16px;
        }
    }
    .content-box {
        padding-top: 12px;
        color:#bebebe;
        font-size:14px;
    }
}

.back {
    margin-top:30px;
    width:190px;
    height:45px;
    line-height: 45px;
    background: #25aae1;
    border:1px solid;
    border-color:#fff;
    border-radius:6px;
    text-align: center;
    font-weight:700;
    color:#ffffff;
    font-size:20px;
    cursor: pointer;
}
</style>