<!-- 公告和站内信 -->
<template>
    <div class="viewsPages">
        <div class="page-notice-mail">
            <div class="tab-notice-mail">
                <!-- 顶部Tab -->
                <Tab ref="tab" :arr="tabArr" @tab="tab" :activeIndex="activeIndex" />
            </div>
            <div class="notice-mail-content">
                <!-- 站内信 -->
                <MailList v-if="activeIndex == 0" />
                <!-- 公告 -->
                <NoticeList v-if="activeIndex == 1" />
            </div>
        </div>
    </div>
</template>

<script>
import NoticeList from './NoticeList.vue'
import MailList from './MailList.vue'
import Tab from '../components/MailTab.vue'
export default {
    components: {
        NoticeList,
        MailList,
        Tab,
    },
    
    data () {
        return {
            activeIndex: 0,
            tabArr: []
        }
    },
    methods: {
        
        // 切换导航
        tab (num) {
            if (this.activeIndex == num) return
            this.activeIndex = num
        }
    }
}
</script>

<style scoped lang="scss">
.page-notice-mail {
    width: 1100px;
    min-height: 600px;
    margin: 0 auto;
    padding: 20px;
    .title {
        font-weight:700;
        color:#ffffff;
        font-size:30px;
    }
    .line {
        width:846px;
        height:2px;
        margin-top:36px;
        background-color:#000;
    }
    .tab-notice-mail{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .notice-mail-content{
        margin: 20px 0;
    }
}
</style>